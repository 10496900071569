.tux-box {
    width: 100%;
  
    .tux-box-container {
      width: 100%;
      max-width: 1600px;
      height: 100vh;
      margin: auto;
      position: relative;
      display: flex;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative; 
      }
    }
  }
  
  .box-call-to-action {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 60px 0 60px;
  
    label {
      background-color: #111;
      color: #fff;
      padding: 10px;
      font-weight: normal;
      margin-bottom: 15px;
      border-radius: 4px;
      border-left: 5px solid #efc155;
    }
  
    h1 {
      font-size: 60px;
      line-height: 62px;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
    }
  
    p {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.02em;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
      padding: 24px 0;
    }
  }

  .rent-box-data{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 60px; 

    .rent-box-text{
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: start;
      justify-content: center;
      padding-right: 20px;
  
      h2{
        padding-bottom: 25px;
        text-align: start;
      }
      p{
        line-height: 25px;
        font-size: 18px;
      }
    }

    
  }
  
  .rent-box-img{
    width: 50%;
    img{
      width: 100%;
      object-fit: cover;
      height: auto;
    }
  }

  .rent-contact-data{
    padding-top: 40px;
    h4{
      padding-bottom: 15px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 19px;
    }
    p{
      padding-bottom: 5px;
      a{
        text-decoration: underline;
      }
    }
  }

  #box-contact{
    top: -30px;
    position: relative;
  }
  
  @media (min-width: 800px) and (max-width: 1050px) {
    .box-call-to-action {
      padding: 0 20px;
    }
  }
  
  @media (min-width: 299px) and (max-width: 799px) {
    .box-call-to-action {
      padding: 0 20px;
      h1 {
        font-size: 30px;
        line-height: 40px;
      }
      p {
        font-size: 16px;
        padding: 10px 0;
      }
    }

    .salto{
      display: none;
    }

    .rent-box-data{
      flex-direction: column;
      padding: 40px 20px; 
      .rent-box-text{
        width: 100%;
        h2{
          line-height: 30px;
        }
      }
      .rent-box-img{
        width: 100%;
        margin-top: 40px;
      }
    }

    .rent-contact-data{
      h4{
        font-size: 16px;
      }
    }
  }
  