.contact {
  width: 100%;
  height: auto;
  .contact-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 55px 60px;
    display: grid;
    grid-template-columns: 50% 50%;
    //background: #ececec;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  padding-top: 30px;

  input {
    margin-bottom: 15px;
    height: 30px;
    border: none;
    border-bottom: 1px solid #111;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #a4a4a4;
    padding-bottom: 7px;
  }

  textarea {
    margin-bottom: 15px;
    height: 60px;
    border: none;
    border-bottom: 1px solid #111;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #a4a4a4;
    padding-bottom: 7px;
  }
}

.btn-submit {
  color: #ffffff;
  background: #111;
  padding: 10px 27px;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
}

::placeholder {
  color: #a4a4a4;
}

.our-stores {
  padding-top: 30px;
}

.location {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
  .adress {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 0;
    p {
      padding-left: 6px;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }
  span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    padding-top: 2px;
  }
}

.stores {
  padding-left: 50px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .contact .contact-container {
    padding: 55px 20px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .contact .contact-container {
    padding: 55px 20px;
    grid-template-columns: 100%;
  }

  .stores {
    padding-left: 0;
    margin-top: 30px;
  }
}
