.button {
  color: #ffffff;
  background: #111111;
  padding: 14px 30px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  transition: all 0.5s ease;

  &:hover {
    background-color: #111111e7;
  }
}

.btn-black {
  background-color: #efc155;
  color: #111;
  &:hover {
    background-color: #efc155e1;
  }
}
