.all-colection-page {
  width: 100%;
  max-width: 1600px;
  padding: 120px 60px 30px 60px;
  margin: 0 auto;

  h3 {
    text-align: center;
  }

  .grid-catalog {
    width: 100%;
    margin-top: 34px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    row-gap: 30px;
  }
}

.delivery-data {
  width: 100%;
  max-width: 1600px;
  margin: 0;
  padding: 30px 60px 60px 60px;
  display: grid;
  grid-template-columns: 50% 50%;
  //background-color: #ececec;
}

.all-colection-head {
  display: flex;
  position: relative;
  height: 250px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  border-radius: 4px;
  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  .all-catalog-title {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.18);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
      font-weight: 900;
      font-size: 38px;
      line-height: 46px;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    p {
      font-family: Playfair Display;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    span img {
      max-width: 120px;
      display: flex;
      padding: 6px 0 4px 0;
    }
  }
}

.catalog-categories {
  h2 {
    font-weight: 900;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: -0.02em;
    font-family: "Lato", sans-serif;
    text-transform: none;
    padding-top: 34px;
  }
}

@media (min-width: 700px) and (max-width: 1050px) {
  .all-colection-page {
    padding: 120px 20px 30px 20px;
    .grid-catalog {
      grid-template-columns: 33.3% 33.3% 33.3%;
    }
  }
  .delivery-data {
    padding: 30px 20px 60px 20px;
  }
}

@media (min-width: 299px) and (max-width: 699px) {
  .all-colection-page {
    padding: 120px 20px 30px 20px;
    .grid-catalog {
      grid-template-columns: 50% 50%;
    }
  }
  .delivery-data {
    padding: 30px 20px 60px 20px;
    grid-template-columns: 100%;
  }
}
