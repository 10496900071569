.header {
  width: 100%;
  height: auto;
  position: absolute;
  z-index: 1;
  .inner-header {
    //max-width: 1600px;
    //margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}

.fix {
  position: fixed;
  z-index: 1000;
}

.banner-store {
  background: #111111;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}

.nav-bar {
  width: 100%;
  height: 60px;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.84) 36.98%,
    rgba(0, 0, 0, 0) 100%
  );

  .brand {
    width: 25%;
    display: flex;
    align-items: center;
    .links {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-transform: uppercase;
      font-family: "Playfair Display", serif;
      color: #fff;
    }
    .black-link {
      color: #111111;
    }
  }

  .nav-menu {
    width: 75%;
    display: flex;
    justify-content: space-between;

    .nav-links {
      width: 67%;
      display: flex;
      justify-content: center;
      align-items: center;
      .links {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-transform: uppercase;
        font-family: "Playfair Display", serif;
        padding: 0 12px;
        color: #fff;
      }
      .black-link {
        color: #111111;
      }
    }
    .find-store-btn {
      width: 33%;
      display: flex;
      justify-content: flex-end;
      .link-stores {
        font-family: Playfair Display;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        border: 1px solid #fff;
        background-color: #ffffff1a;
        border-radius: 4px;
        padding: 9px 24px;
        color: #fff;
        transition: all 0.5s ease;
        &:hover {
          background-color: #ffffff57;
        }
      }
      .black-link-stores {
        border: 1px solid #111111;
        color: #111111;
        &:hover {
          background-color: #111111;
          color: #fff;
        }
      }
    }
  }
}

.white-nav-bar {
  background: #fff;
  border-bottom: 1px solid #d7d7d7;
}

.hamburger {
  display: none;
}

.brand img {
  width: 160px;
  padding-top: 6px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .nav-bar {
    padding: 0 20px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .nav-bar {
    padding: 0 20px;
  }

  .responsive {
    background: none;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;
  }

  .hamburger {
    display: flex;
  }

  .nav-bar .brand {
    width: 50%;
  }

  .nav-bar .nav-menu {
    position: fixed;
    background-color: #fff;
    width: 100%;
    left: 0;
    top: 90px;
    flex-direction: column;
    height: 100vh;
    justify-content: normal;
    padding: 20px 0;
    overflow: hidden;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    max-height: 0;
  }

  .nav-bar .nav-menu .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    .links {
      padding: 12px 0;
      font-size: 18px;
      width: 100%;
      text-align: center;
      color: #111;
    }
  }

  .nav-bar .nav-menu .find-store-btn {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    .link-stores {
      color: #111;
      border: 1px solid #111;
    }
  }

  .banner-store p {
    font-size: 10px;
  }

  .nav-bar .showMenu {
    max-height: 1000px;
    transform: translateX(0);
  }
}
