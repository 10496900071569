.carrousel-products {
  width: 100%;
  height: auto;
  .carrousel-products-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0px 60px 0px 60px;

    h2 {
      padding: 29px 0 29px 0;
    }
  }
}

.carrousel {
  width: 100%;
  padding-bottom: 30px;
}

.product-card {
  width: 100%;
  height: 420px;
  max-width: 350px;
  margin-right: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  border-radius: 4px;
  position: relative;
}

.image-card {
  width: 100%;
  height: auto;
  img {
    width: 98%;
    height: 100%;
    object-fit: cover;
    position: relative;
    border-radius: 4px;
    transition: transform 0.2s linear;
  }
}

.name-price {
  width: 98%;
  height: 100%;
  position: absolute;
  padding: 10px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  transition: all 0.3s ease;
  background: linear-gradient(
    180deg,
    rgba(17, 17, 17, 0) 66.67%,
    rgba(17, 17, 17, 0.75) 88.24%
  );

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #fff;
    padding-bottom: 6px;
    transition: all 0.3s ease;
  }
  span {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    text-align: end;
    color: #fff;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.02em;
    transition: all 0.2s ease;
  }
}

.name-price:hover {
  p {
    color: rgba(255, 255, 255, 0.733);
  }
  span {
    color: rgba(255, 255, 255, 0.733);
  }
}

.off {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px;
  background-color: #efc155;
  font-weight: bold;
  border-radius: 4px;
  font-size: 14px;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .carrousel-products .carrousel-products-container {
    padding: 0px 20px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .carrousel-products .carrousel-products-container {
    padding: 0px 20px;
  }
}
