.our-stores-page {
  width: 100%;
  height: 100vh;
  max-width: 1600px;
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 90px;

  .location-container {
    padding-left: 60px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;

    h3 {
      padding-bottom: 30px;
    }
  }
}

.stores-open {
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 9px;
    padding-top: 25px;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 6px;
  }
}

.map {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .maps-tab {
    display: flex;
    justify-content: space-between;
    transition: all 0.5s ease;
  }
}

.tab {
  width: 50%;
  text-align: center;
  background: #efc155;
  color: #fff;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.5s ease;
  font-size: 14px;
}

.bold {
  color: #fff;
  background-color: #d7d7d7;
  transition: all 0.5s ease;
}

.showMap {
  display: flex;
}

.none {
  display: none;
}

@media (min-width: 299px) and (max-width: 699px) {
  .our-stores-page {
    grid-template-columns: 100%;
  }

  .our-stores-page .location-container {
    padding-left: 20px;
  }

  .map {
    display: none;
  }
}
