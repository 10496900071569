.how-it-works {
  width: 100%;
  height: auto;
  .how-it-works-container {
    width: 100%;
    max-width: 1600px;
    padding: 30px 60px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.text {
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  background-color: #ffffff;

  h2 {
    padding-bottom: 20px;
  }
}

.image {
  width: 50%;
  height: auto;
  img {
    width: 100%;
    max-width: 600px;
    height: 100%;
    min-height: 600px;
    object-fit: cover;
  }
}

.how-it-works-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  margin: 0;
  .item-icon {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
  }
  .item-text {
    border-bottom: 1px solid #dbdbdb;
    padding: 17px 0;
    margin-left: 15px;
    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      padding-top: 4px;
    }
  }
}

@media (min-width: 700px) and (max-width: 1050px) {
  .how-it-works .how-it-works-container {
    padding: 30px 20px;
  }

  .text {
    padding: 0 30px;
  }
}

@media (min-width: 299px) and (max-width: 699px) {
  .how-it-works .how-it-works-container {
    padding: 30px 20px;
    flex-direction: column;
    flex-flow: column-reverse;
    .text {
      width: 100%;
      padding: 0;
      margin-bottom: 15px;
    }
    .image {
      width: 100%;
      img {
        min-height: auto;
      }
    }
    .how-it-works-item .item-text h4 {
      font-size: 18px;
    }
    .how-it-works-item .item-text p {
      font-size: 16px;
    }
  }
}
