.support {
  width: 100%;
  height: auto;
  max-width: 900px;
  margin: 0 auto;
  padding: 160px 15px 0 15px;

  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 31px;
    padding-bottom: 16px;
  }
}

.support-box {
  margin-top: 60px;
  h4 {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 16px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 16px;
  }
}

.support-link-stores {
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
}

.last-box {
  margin-bottom: 96px;
  display: flex;
  flex-direction: column;
  .support-link-social {
    padding-bottom: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}
