.detail-product-page {
  width: 100%;
  height: auto;
  .detail-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 120px 60px 30px 60px;
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.detail-product-data-box {
  display: flex;
  flex-direction: column;
  h5 {
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }
  span {
    font-family: Arial;
    font-style: normal;
    font-size: 23px;
    line-height: 30px;
    letter-spacing: -0.02em;
    padding: 18px 0;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.02em;
    max-width: 500px;
  }
}

.btn-black {
  color: #ffffff;
  background: #111;
  padding: 14px 27px;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  border-radius: 3px;
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
}

.rent-btn-box {
  width: 100%;
  max-width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.detail-product-img {
  width: 100%;
  max-width: 550px;
  height: auto;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.more-products {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 60px;
  h3 {
    padding-bottom: 28px;
  }
}

.tuxedos-includes {
  margin-top: 36px;
  max-width: 500px;
  h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: 3px;
  }
  ul {
    padding-left: 15px;
    padding-top: 10px;
    li {
      padding: 3px 0;
      font-weight: 300;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.02em;
    }
  }
}

@media (min-width: 700px) and (max-width: 1050px) {
  .detail-product-page .detail-container {
    padding: 120px 20px 30px 20px;
    .detail-product-data {
      padding: 0 15px;
    }
  }
  .more-products {
    padding: 0 20px;
  }
}

@media (min-width: 299px) and (max-width: 699px) {
  .detail-product-page .detail-container {
    padding: 120px 20px 30px 20px;
    grid-template-columns: 100%;
    .detail-product-data {
      margin-top: 20px;
      .detail-product-data-box {
        h5 {
          font-size: 21px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }
  .more-products {
    padding: 0 20px;
    h3 {
      font-size: 26px;
      padding-bottom: 5px;
    }
  }
}
