.pop-up{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(17, 17, 17, 0.664);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.pop-up-box{
  background-color: #fff;
  width: 100%;
  max-width: 650px;
  height: 100%;
  max-height: 580px;
  display: flex;
  justify-content: space-between;
}

.content-pop-up{
  width: 50%;
  padding: 20px;
  .text-pop-up{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3{
      font-size: 16px;
      line-height: 23px;
      padding-bottom: 10px;
      color: #111;
      text-transform: none;
      font-weight: 300;
    }
    p{
      font-size: 18px;
      padding-bottom: 10px;
      
    }
    span{
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      font-weight: 800;
      padding-bottom: 10px;

    }
    .anexo{
      font-size: 14px;
      text-transform: none;
      letter-spacing: -0.7px;
      background-color: #efc155;
      color: #111;
      padding: 5px 10px;
      border-radius: 4px;
      text-align: center;
      b{
        color: #fff;
      }
    }
  }
}

.pop-up-btn{
  display: flex;
  justify-content: flex-end;
  button{
    font-size: 16px;
    font-weight: 700;
  }
}

.img-pop-up{
  width: 50%;
  height: 100%;
  background-image: url('../assets/proms-2025.jpeg');
  background-position: center 60%;
  background-size: cover;
  background-repeat: no-repeat;
}


@media (min-width: 299px) and (max-width: 699px) {
  .pop-up-box{
    flex-direction: column;
    max-height: 750px;
    
  }
  .content-pop-up{
    width: 100%;
    padding: 10px 20px 20px 20px;
  }
  .img-pop-up{
    width: 100%;
  }
  .img-pop-up{
    height: 100%;
    background-image: url('../assets/proms-2025.jpeg');
    background-position: center 25%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .content-pop-up{
    .text-pop-up{
      h3{
        font-size: 14px;
        line-height: 21px;
       text-align: center;
      }
      span{
        font-size: 16px;
       text-align: center;

  
      }
    }
  }
}