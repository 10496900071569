.support-btn {
  position: fixed;
  right: 60px;
  top: 85%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 12px 30px;
  border-radius: 80px;
  z-index: 999999;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.22);
  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-left: 5px;
    padding-bottom: 1px;
  }
}

@media (min-width: 299px) and (max-width: 1050px) {
  .support-btn {
    right: 20px;
  }
}

@media (min-width: 299px) and (max-width: 599px) {
  .support-btn {
    right: 20px;
    padding: 16px 20px;
    p {
      display: none;
    }
  }
}
