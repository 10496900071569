.about {
  width: 100%;
  height: auto;
  .about-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 30px 60px 60px 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.img-about {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  img {
    width: 100%;
    max-width: 600px;
    height: 100%;
    min-height: 600px;
    object-fit: cover;
  }
}

.about-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 60px;
  span {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
  }
  h2 {
    width: 100%;
    text-align: start;
    padding-bottom: 21px;
  }
  p {
    width: 100%;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    max-width: 450px;
    text-align: start;
  }
}

@media (min-width: 700px) and (max-width: 1050px) {
  .about .about-container {
    padding: 30px 20px 60px 20px;
    .about-text {
      padding: 0 30px;
    }
  }
}

@media (min-width: 299px) and (max-width: 699px) {
  .about .about-container {
    padding: 30px 20px 60px 20px;
    flex-direction: column;
    .img-about {
      width: 100%;
      img {
        min-height: auto;
      }
    }
    .about-text {
      width: 100%;
      margin-bottom: 30px;
      padding: 0;
    }
  }
}

.about-link-btn {
  margin-top: 30px;
}
