.footer {
  width: 100%;
  height: auto;
  border-top: 1px solid #d7d7d7;
  background-color: #efc155;
  .footer-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 65% 35%;
    padding: 50px 0px;
    border-bottom: 1px solid #111111;
  }
}

.footer-link {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  div {
    display: flex;
    flex-direction: column;
    h4 {
      font-weight: 800;
      font-size: 16px;
      line-height: 18px;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
    .link {
      padding-bottom: 6px;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.footer-brand {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: end;
  img {
    width: 24px;
    margin-left: 8px;
  }
}

.rights {
  text-align: center;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding: 20px 0;
  .temrs{
    font-weight: 700;
    text-decoration: underline;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .footer .footer-container {
    padding: 50px 20px;
  }
}

@media (min-width: 299px) and (max-width: 599px) {
  .footer .footer-container {
    padding: 50px 20px;
    grid-template-columns: 100%;
    .footer-link {
      grid-template-columns: 100%;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .footer-brand {
    justify-content: center;
  }
}
