.faqs {
  width: 100%;
  height: auto;
  background: #ececec;
  padding: 40px 0;
  .faqs-container {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
}

.faqs-list {
  padding: 20px 60px;
  display: grid;
  grid-template-columns: 49% 49%;
  column-gap: 2%;
}

.faq {
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #dbdbdb;
}

.question {
  position: relative;
  padding: 15px 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.answer {
  max-height: 0;
  overflow: hidden;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  padding: 0px 16px;
}

.show-answer {
  max-height: 1000px;
  padding-bottom: 5px;
}

.faqs-all-question {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  text-decoration: underline;
  padding-bottom: 1px;
  font-weight: 500;
}

.faqs-page {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 120px 60px 30px 60px;
  background: #ececec;
  h3 {
    text-align: center;
  }
}

.faqs-full-list {
  padding-top: 30px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

@media (min-width: 299px) and (max-width: 799px) {
  .faqs-list {
    grid-template-columns: 100%;
    padding: 20px;
  }
  .faqs-page {
    padding: 120px 20px 30px 20px;
  }
}
