* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  color: #111;
  font-family: "Lato", sans-serif;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
  background: #fff;
}

h1 {
  font-family: "Playfair Display", serif;
  font-weight: normal;
}

h2 {
  font-family: "Playfair Display", serif;
  font-size: 38px;
  line-height: 51px;
  color: #111111;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}

h3 {
  font-style: normal;
  font-weight: 900;
  font-size: 38px;
  line-height: 46px;
  text-transform: uppercase;
}

.go-with-google {
  display: flex;
  align-items: center;
  padding-top: 10px;
  a {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #5d5fef;
    padding-right: 8px;
    text-transform: uppercase;
  }
}

.react-multiple-carousel__arrow--right {
  background-color: #111111;
}

.react-multiple-carousel__arrow--left {
  background-color: #111111;
}

.sale {
  background: #333333;
  background-color: #687b66;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-transform: uppercase;
  p {
    color: #fff;
    color: #eaf867;
    padding-right: 10px;
    font-weight: 600;
    span {
      font-weight: bold;
      color: #efc155;
      text-decoration: underline;
    }
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  h2 {
    font-size: 26px;
  }
  .sale {
    padding: 9px;
    font-size: 12px;
  }
}
