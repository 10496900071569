.cover {
  width: 100%;

  .cover-container {
    width: 100%;
    //max-width: 1600px;
    height: 100vh;
    margin: auto;
    position: relative;
    display: flex;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
  }
}

.call-to-action {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.25);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 60px 0 60px;
  // max-width: 1600px;

  label {
    background-color: #111;
    color: #fff;
    padding: 10px;
    font-weight: normal;
    margin-bottom: 15px;
    border-radius: 4px;
    border-left: 5px solid #efc155;
  }

  h1 {
    font-size: 60px;
    line-height: 62px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
  }

  p {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
    padding: 24px 0;
  }
}

.img-responsive {
  display: none;
}

@media (min-width: 800px) and (max-width: 1050px) {
  .call-to-action {
    padding: 0 20px;
  }
}

@media (min-width: 299px) and (max-width: 799px) {
  .call-to-action {
    padding: 0 20px;
    h1 {
      font-size: 30px;
      line-height: 40px;
    }
    p {
      font-size: 16px;
      padding: 10px 0;
    }
  }

  .desktop {
    display: none;
  }

  .img-responsive {
    display: flex;
  }
}
