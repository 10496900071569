.tc-container{
  width: 100%;
  height: auto;
  .inner-tc{
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 120px 60px 80px 60px;

    h3{
      width: 100%;
      max-width: 850px;
      margin: 0 auto;
      font-size: 18px;
    }
    .item{
      width: 100%;
      max-width: 750px;
      margin: 0 auto;
    }
  }
}